import React from 'react'
import Avatar from '../Avatar'
import * as S from './styled'

const Profile = () => {
  return (
    <S.ProfileWrapperLink>
        <Avatar />
        <S.ProfileAuthor>
          marcos cury
        </S.ProfileAuthor>
    </S.ProfileWrapperLink>
  )
}

export default Profile