import React from 'react'

import * as S from './styled'

import Profile from '../Profile'
import MenuLinks from '../MenuLinks'

const Header = () => (
  <S.HeaderWrapper>
    <S.HeaderContainer>
      <Profile />
      <MenuLinks />
    </S.HeaderContainer>
  </S.HeaderWrapper>
)

export default Header