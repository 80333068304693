import styled from 'styled-components'

export const LayoutWrapper = styled.section`
  padding-top: 2rem;
  padding-bottom: 3rem;
`

export const LayoutMain = styled.main`
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
`