import styled from "styled-components"
import { Link } from "gatsby"
import { Tags } from "@styled-icons/fa-solid/Tags"

export const PostCardWrapper = styled.section`
  /* width: 100%; */
  /* height: 15rem; */
  background-color: #fafafa;
  padding: 1rem;
  margin-bottom: 2rem;
  /* border-left: 1px solid #cccccc; */
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: 1px 1px 2px 1px rgba(100, 100, 100, 0.15);
  transition: box-shadow 0.5s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  /* border-radius: 1%; */

  &:hover {
    /* border-left: 1px solid #777777; */
    /* background-color: #EFEFEF; */
    box-shadow: 1px 1px 2px 1px rgba(100, 100, 100, 0.85);
  }
`

export const PostCardLink = styled(Link)`
  text-decoration: none;
`

export const PostCardTitle = styled.h2`
  color: #000000;
  margin-bottom: 1rem;
  font-size: 1.7rem;
  font-weight: 700;
  font-weight: 600;
  font-family: "Montserrat";
`

export const PostCardDate = styled.p`
  margin-bottom: 1rem;
  color: #555555;
  font-size: 0.8rem;
`

export const PostCardDescription = styled.p`
  margin-bottom: 1rem;
  color: #555555;
  text-align: justify;
  line-height: 1.7rem;
  font-size: 1.2rem;
  font-family: "Open Sans";
  letter-spacing: 0.049rem;
`

export const PostCardTagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: 1rem;
  align-items: center;
`

export const PostCardTagListItem = styled.li`
  margin-right: 1rem;
  color: #272d2d;
  border-bottom: 1px dashed #272D2D;
`

export const TagIcon = styled(Tags)`
  color: #272d2d;
  margin-right: 1rem;
`
