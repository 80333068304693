import styled from "styled-components"
import { Link } from "gatsby"
import media from "styled-media-query"

export const MenuLinksWrapper = styled.div`
  ${media.lessThan("medium")`
    padding-right: 1rem;
  `}
`

export const MenuLinksNavigator = styled.nav``

export const MenuLinksLink = styled(Link)`
  text-decoration: none;
  color: #f7f8fa;
  transition: text-decoration 0.5s;
  margin-left: 2rem;

  &:hover {
    text-decoration: underline;
  }
`
