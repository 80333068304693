import styled from 'styled-components'
import { Link } from 'gatsby'
import media from 'styled-media-query'

export const ProfileWrapperLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #f7f8fa;
  text-decoration: none;
  color: #f7f8fa;
  ${media.lessThan("medium")`
    margin-left: 0.5rem;
  `}

  img {
    ${media.lessThan("medium")`
      width: 40px;
      height: 40px;
    `}
  }
`

export const ProfileAuthor = styled.h1`
  margin-left: 1rem;
`