import React from 'react'
import { Github } from '@styled-icons/boxicons-logos/Github'
import { LinkedinSquare } from '@styled-icons/boxicons-logos/LinkedinSquare'
import { Twitter } from '@styled-icons/boxicons-logos/Twitter'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'
import { Mail } from '@styled-icons/entypo/Mail'

import * as S from './styled'

const Footer = () => (
  <S.FooterWrapper>
    <S.FooterContainer>
      <a href="https://github.com/marcospcury" target="_blank" rel="noopener, nofollow"><Github size="30" title="Github" /></a>
      <a href="https://www.linkedin.com/in/marcospcury/" target="_blank" rel="noopener, nofollow"><LinkedinSquare size="30" title="LinkedIn" /></a>
      <a href="https://twitter.com/marcospcury" target="_blank" rel="noopener, nofollow"><Twitter size="30" title="Twitter" /></a>
      <a href="mailto:marcospcury@gmail.com" rel="noopener, nofollow"><Mail size="30" title="Email" /></a>
    </S.FooterContainer>
  </S.FooterWrapper>
)

export default Footer
