import React from "react"

import * as S from "./styled"

const PostCard = ({
  slug,
  category,
  title,
  date,
  description,
  tags,
  timeToRead,
  image
}) => (
  <>
    <S.PostCardWrapper>
      <S.PostCardLink to={slug}>
        <S.PostCardTitle>{title}</S.PostCardTitle>
        <S.PostCardDate>
          {date} - {timeToRead} min de leitura
        </S.PostCardDate>
        <S.PostCardDescription>{description}</S.PostCardDescription>
        <S.PostCardTagList>
          <S.TagIcon size="20" />
          {tags.map(tag => (
            <S.PostCardTagListItem>{tag}</S.PostCardTagListItem>
          ))}
        </S.PostCardTagList>
      </S.PostCardLink>
    </S.PostCardWrapper>
  </>
)

export default PostCard
