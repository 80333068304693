import styled from 'styled-components'
import media from 'styled-media-query'

export const HeaderWrapper = styled.header`
  background-color: #272D2D;
  color: #E8E8E5;
  width: 100%;
  height: 5rem;
  /* position: fixed; */
  border-bottom: 1px solid #170312;
  font-size: 1.5rem;
  font-family: Montserrat;
  font-weight: 600;

  ${media.lessThan("medium")`
    font-size: 1.2rem;
  `}
`

export const HeaderContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: space-between;
`