import React from 'react'
import * as S from './styled'

const MenuLinks = () => (
  <S.MenuLinksWrapper>
    <S.MenuLinksNavigator>
      <S.MenuLinksLink to="/eu">eu</S.MenuLinksLink>
      <S.MenuLinksLink to="/tech">tech</S.MenuLinksLink>
      <S.MenuLinksLink to="/misc">misc</S.MenuLinksLink>
    </S.MenuLinksNavigator>
  </S.MenuLinksWrapper>
)

export default MenuLinks