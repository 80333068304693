import React from 'react'

import Header from '../Header'
import Footer from '../Footer'

import * as S from './styled'

import GlobalStyles from '../../styles/global'

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Header />
      <S.LayoutWrapper>
        <S.LayoutMain>
          {children}
        </S.LayoutMain>
      </S.LayoutWrapper>
      <Footer />
    </>
  )

}

export default Layout
