import styled from 'styled-components'
import media from 'styled-media-query'

export const FooterWrapper = styled.footer`
  background-color: #272D2D;
  color: white;
  width: 100%;
  height: 2.5rem;
  position: fixed;
  display: block;
  bottom: 0;
  border-top: 1px solid #170312;
`

export const FooterContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: center;

  a {
    text-decoration: none;
    color: #E8E8E5;
  }

  svg {
    margin-right: 1rem;
  }

  ${media.lessThan("medium")`
    margin-left: 1rem;
    margin-right: 1rem;
  `}
`