import React from "react"

import PostCard from "../PostCard"

const PostsList = ({ postList }) => {
  return (
    <>
      {postList.map(
        ({
          node: {
            frontmatter: {
              category,
              date,
              description,
              title,
              pinned,
              tags,
              image
            },
            timeToRead,
            fields: { slug }
          }
        }) => (
          <PostCard
            slug={slug}
            title={title}
            date={date}
            description={description}
            tags={tags}
            timeToRead={timeToRead}
            pinned={pinned}
            image={image}
          />
        )
      )}
    </>
  )
}

export default PostsList
