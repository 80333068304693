import styled from 'styled-components'
import Img from 'gatsby-image'
import media from 'styled-media-query'

export const AvatarWrapper = styled(Img)`
  margin-right: 1rem;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: auto;

  ${media.lessThan("medium")`
    width: 40px;
    height: 40px;
    margin-right: 0;
  `}
`